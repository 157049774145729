*,
:after,
:before {
    box-sizing: border-box;
}

img {
    height: auto;
    width: 100%;
}

body {
    margin: 0;
    /*background-color: gainsboro;*/
    /*background: linear-gradient(135deg, rgb(102, 126, 234) 0%, rgb(118, 75, 162) 100%);*/
    /*height: 100vh;*/
}

a {
    text-decoration: none;
    color: #ff4081;
}

/*a:visited {*/
/*color: #ff4081;*/
/*}*/

.cropper-dashed {
    border: 0 dashed red!important;
}

.cropper-line {
    background-color: red!important;
}

.cropper-point {
    width: 5px;
    height: 5px;
    opacity: .75;
    background-color: red!important;
}

.lineCounter {
    position: relative;
}

.lineCounter div:nth-child(1) {
    position: absolute;
    right: 0;
    top: 0
}

.lineCounter div:nth-child(2) {
    width: 80%
}
